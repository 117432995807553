import React, { useState } from "react";
import { graphql } from "gatsby"
import Layout from "./../../components/layout"
import SEO from "./../../components/seo"
import Img from "gatsby-image"
import { OutboundLink } from 'gatsby-plugin-google-analytics';


//import css
import "./../../styles/integrations-slack.css"
//import components
import GetStartedBanner from "./../../components/get-started-footer"

const DiscordIntegrationPage = ({ data }) => (
  <Layout>
    <SEO
      title="Connect Discord to your Odown monitors"
      description="Receive real-time alerts from Odown to your preferred Discord channels."
      pathname={`/integrations/discord/`}
      image={{
        src: "/general-image-og--odown_integration_discord.jpg",
        width: 1600,
        height: 800,
      }}
    />
    <HeadingSection imageData={data.FeatureBreakDownImage1.childImageSharp.fluid}/>
    <Description />
    <CombinedSections />
    <CombinedSections2 />
    <MonitoringToolsSection />
    <Howtouse />
    <SuperchargeYourIncidentResponseSection />
    <BuiltforTeamsofAllSizes />
    <SecureandReliable />
    <TryOdownSection />
    <ClosingSection />
    <GetStartedBanner />
  </Layout>
)

const HeadingSection = ({ imageData }) => {
    const [getStartedButton, setGetStartedButton] = useState({
      name: "Get started for free",
      link: "https://app.odown.io/signup",
    });
  
    return (
      <div className="u-features slack-hero" style={{ padding: '30px 20px' }}>
        <div className="flex-container">
          <div className="text-section">
            <h1 className="custom-heading-title">
            Stay on Top of Website Odowntime with Odown's Discord Integration
            </h1>
            <div className="u-features__heading-btn center-button">
              <OutboundLink
                className="custom-btn custom-btn-primary"
                eventCategory="Launch the Odown App"
                eventAction="Click"
                eventLabel="Goto signup"
                href={getStartedButton.link}
                target="_blank"
              >
                {getStartedButton.name}
              </OutboundLink>
            </div>
          </div>
          <div className="image-section">
            <Img
              fadeIn={false}
              loading="eager"
              fluid={imageData}
              alt="Integration - Discord"
            />
          </div>
        </div>
      </div>
    );
  };  
const Description = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <p className="u-main-feature_tc-desc2">
    Website monitoring is a modern technology that has more 
    capabilities than the traditional monitoring systems that
     are used for different tasks. At present Odown can do most
      of the monitoring work for example giving instant alerts 
      and helping teams collaborate on problem-solving, all 
      within Discord servers that teams regularly use.   
       </p>
     </div>
  </section>
)

const CombinedSections = ({ imageData }) => (
    <div className="sections-container">
        <h2 className="centered-heading">Why Connect Odown with Discord?</h2>
        <div className="real-time-section">
            <RealTimeNotificationSection imageData={imageData} />
        </div>
        <div className="streamlined-section">
            <StreamlinedIncidentManagementSection imageData={imageData} />
        </div>
    </div>
);

  const RealTimeNotificationSection = ({ imageData }) => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">Real-Time Notifications</h3>
              <p className="u-main-feature_tc-desc">
              Manual dashboard checking and email alerts are inefficient 
              in monitoring work. As of now such notifications need proper 
              configuration, and initially they need good setup and now 
              proper channel organization too. These alerts make monitoring 
              very effective by giving you diverse possibilities which help to complete your task.
              </p>
             </div>
          </div>
        </div>
      </div>
    </section>
  );
  
  const StreamlinedIncidentManagementSection = ({ imageData }) => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">Streamlined Incident Management</h3>
              <p className="u-main-feature_tc-desc">
              Discord platform adds many advantages for team coordination. 
              At present teams can exchange information and work updates, 
              with the help of different features like member tagging you 
              can do various tasks like work assignment and progress monitoring. 
              These features make incident management very systematic by giving you varied possibilities. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

const CombinedSections2 = () => (
    <div className="sections-container">
        <div className="real-time-section">
            <CustomizableForYourWorkflowSection />
        </div>
        <div className="streamlined-section">
            <A360ViewOfPerformanceSection />
        </div>
    </div>
);

const CustomizableForYourWorkflowSection = () => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">Customizable for Your Workflow</h3>
              <p className="u-main-feature_tc-desc">
              Various businesses need different monitoring arrangements. 
              Today, such arrangements need proper channel structure, and 
              initially they need good alert setup and now proper workflow 
              organization too. These settings convey many things such as, 
              emergency alerts in #it-alerts and also natural elements like 
              priority levels which symbolize different urgencies.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
  
  const A360ViewOfPerformanceSection = () => (
    <section className="u-main-feature integrations-slack-section">
      <div className="container">
        <div className="u-main-feature__wrapper">
          <div className="u-main-feature__text-content">
            <div className="formatted-text">
              <h3 className="u-main-feature_tc-title">A 360° View of Performance</h3>
              <p className="u-main-feature_tc-desc">
              Improper monitoring is very dangerous during website management. 
              Different metrics is used and with the help of proper tracking 
              they make very detailed performance analysis that is of great use 
              for website health. Also, teams are benefited from this as they 
              take proper measures and use proper tools to maintain performance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const MonitoringToolsSection = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <div className="u-main-feature_tc-desc2">
    These monitoring tools according to experts is 
    extremely crucial to website management and this 
    proves just how important proper monitoring is and how they 
    sustain website performance. This integration many depicts 
    different patterns and beautiful possibilities containing 
    stories of the uptime and about website's health and stability.    
       </div>
       <div className="u-main-feature_tc-desc2">
       Preventative Insights is an advanced technique that has 
       greater significance than traditional monitoring methods 
       used for various tasks. Presently monitoring can perform 
       most operations for example a good monitoring system can 
       identify slow response times, with the help of different 
       strategies you can perform various tasks like location-based 
       monitoring and planned maintenance.   
       </div>   
     </div>
  </section>
)
  const Howtouse = () => {
    return (
      <section className="u-main-feature integrations-slack-section" style={{ padding: '30px 20px' }}>
        <div className="container">
          <div className="u-main-feature__wrapper">
            <div className="u-main-feature__text-content">
              <h2 className="content-title1">Quick & Easy Setup</h2>
              <p className="u-main-feature_tc-desc2">
              Discord setup is very straightforward during integration process. 
              Different methods are used and with the help of proper techniques 
              they create very organized setup that is highly beneficial for 
              monitoring. Also, teams are benefited from this as they follow 
              proper steps and use proper techniques to complete integration.
              </p>
    
              <p className="use-desc2">
              1. Access Integrations :
              Incorrect dashboard access is very problematic during setup. 
              Today, such access need proper login credentials, and initially 
              they need good authorization and now proper channel selection too.              
              </p>
              <p className="use-desc2">
              2. Select Discord :
              Integration with Discord enhance alert efficiency. 
              These integrations many shows different patterns and beautiful 
              possibilities containing stories of the monitoring and about team's communication.              
              </p>
              <p className="use-desc2">
              3. Customize Your Preferences :
              Extended time without proper customization can cause notification issues. 
              Today you find proper settings like alert severity and as such can come 
              in handy in the current monitoring environment.              
              </p>
              <p className="use-desc2">
              4. Start Monitoring :
              Proper monitoring helps in incident management success. 
              Currently, teams can receive real-time updates and responsibilities, 
              with the help of different techniques like Discord alerts you can do various tasks safely.              
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  };
  

  const SuperchargeYourIncidentResponseSection = () => {
    return (
      <section className="u-advanced-features" style={{ padding: '30px 20px' }}>
        <div className="container">
          <h2 className="content-title1">Supercharge Your Incident Response</h2>
          <div className="u-advanced-features__items">
          <p className="u-main-feature_tc-desc2">
          Monitoring in incident response increase efficiency and 
          organization. Today, such tools need proper understanding, 
          and initially they need proper setup and now regular updates too. 
          These tools provide many things such as, centralized communication 
          and also natural elements like reduced context switching which symbolize good team alignment.
       </p>
          </div>
        </div>
      </section>
    );
  };
 
  const BuiltforTeamsofAllSizes = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <h2 className="centered-heading">Perfect for Teams of All Sizes</h2>
    <p className="u-main-feature_tc-desc2">
    Teams of different sizes can use this integration that has more 
    flexibility than the normal tools that are used for many different 
    works. These integrations make your work very manageable by giving 
    you diverse possibilities.
       </p>
     </div>
  </section>
)


const SecureandReliable = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <h2 className="centered-heading">Secure & Reliable</h2>
    <p className="u-main-feature_tc-desc2">
    Security in integration is very crucial during setup time. 
    Different methods are used and with the help of proper 
    encryption they make very safe environment that is of 
    great use for teams.   
       </p>
     </div>
  </section>
)


const TryOdownSection = () => (
    <section className="u-main-feature description-section" style={{ padding: '30px 20px' }}>
    <div className="container">
    <h2 className="centered-heading">Try Odown's Discord Integration Today</h2>
    <p className="u-main-feature_tc-desc2">
    Integration setup adds many benefits to monitoring work. 
    Currently, teams can take better control and real-time alerts, 
    with the help of different methods like Discord integration you 
    can do various tasks efficiently.
       </p>
     </div>
  </section>
)

const ClosingSection = () => (
    <div className="sections-container-closing" style={{ padding: '30px 20px' }}>
        <h2 className="centered-heading">
        Need Help or Have Questions?
        </h2>
        <p className="u-main-feature_tc-desc2">
        Support team is ready for help that is quite helpful for setup time.
       </p>
    </div>
);


export const query = graphql`
  query {
    FeatureBreakDownImage1: file(
      relativePath: { eq: "discord_icon.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    
  }
`

export default DiscordIntegrationPage